<template>
  <div class="withdraw-info-wrap">
    <van-nav-bar :border="false" left-text="" @click-left="back" left-arrow>
      <div slot="left">
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot="right">
        <img @click="goHistory" src="@/assets/images/walltes/list.svg" alt="" srcset="">
      </div>
    </van-nav-bar>
    <PullRefresh @refreshFn="refreshFn">
      <div class="withdraw-info-main">
        <div class="padding20">
          <div class="font24 font-weight500 title-color">
            {{this.$t('withdraw.send')}} {{coinInfo.label}}
          </div>
          <!-- <div>
            {{ this.$t('withdraw.sendTip1') }} {{coinInfo.label}} {{ this.$t('withdraw.sendTip2') }}
          </div> -->
        </div>
        <div class="withdraw-content-box">
          <div class="padding-left20 padding-right20">
            <div class="ub ub-h-c">
              <p class=" main-text-2 margin-right8">{{ $t('withdraw.address') }}/{{ $t('withdraw.invoice') }}</p>
              <img @click="invoiceShowFn" src="@/assets/images/walltes/tip.svg" alt="">
            </div>
            <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
              <van-field autosize type="textarea" rows="1" style="width: 80%;" :border="false" class="main-text-1 font14 font-weight500 break-word" v-model="withdrawAddress" :placeholder="$t('withdraw.addressIptTip')" />
              <div class="ub" v-if="false">
                <SelectAddress @callback="getAddress"></SelectAddress>
                <img src="@/assets/images/walltes/scan.svg"/>
              </div>
            </div>
          </div>
          <ChooseNetwork ref="ChooseNetwork" :network="network" @callback="getNetwork"></ChooseNetwork>
          <div class="padding-left20 padding-right20">
            <p class=" main-text-2">{{ $t('withdraw.amount') }}</p>
            <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
              <van-field type="number" rows="1" style="width: 80%;" :border="false" class="main-text-1 font14 font-weight500 break-word" v-model="amount" :placeholder="$t('withdraw.amountIptTip')" />
              <div>
                <span class="main-text-3 margin-right8">{{coinInfo.label}}</span><span class="btn-text-color" @click="setMaxAmount">MAX</span>
              </div>
            </div>
            <div class="error-color" v-if="amount>totalAssets">{{ $t('withdraw.insufficientBalance') }}</div>
            <div class="main-text-3">≈ {{amount || 0 }} USD</div>
            <div class="main-text-3">{{$t('withdraw.available')}} {{ totalAssets || 0 }} USDT</div>
          </div>
          <div class="padding-left20 margin-top20 padding-right20">
            <p class=" main-text-2">{{ $t('withdraw.fee') }}</p>
            <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
              <van-field type="number" rows="1" style="width: 80%;" :border="false" class="main-text-1 font14 font-weight500 break-word" v-model="fee"/>
              <div>
                <span class="main-text-3 margin-right8">{{coinInfo.label}}</span>
              </div>
            </div>
          </div>
          <div class="rule-box margin20 background-color-2">
            <div class="ub ub-btw  padding-right12 padding-left12 padding-bottom8 padding-top8">
              <div class="main-text-2"> {{ $t('withdraw.withdrawalLimit') }} </div>
              <div class="main-text-1">  1,000,000/1,000,000 USDT </div>
            </div>
            <div  class="ub ub-btw padding-right12 padding-left12 padding-bottom8 padding-top8">
              <div class="main-text-2"> {{ $t('withdraw.fee') }} </div>
              <div class="main-text-1">{{this.network.fee}} {{coinInfo.label}}  </div>
            </div>
            <div class="ub ub-btw  padding-right12 padding-left12 padding-bottom8 padding-top8 main-text-2">
              {{ $t('withdraw.wraningTip') }}
            </div>
          </div>
          <div class="footer-btn-box ub ub-btw text-c">
            <div class="btn-box background-color-3 primary-btn-color font16" :class="{'disabled-bg-color':disabled}" @click="fundWithdrawFn">
              {{ $t('withdraw.submit') }}
            </div>
          </div>
        </div>
      </div>
    </PullRefresh>
    <van-dialog
      v-model="invoiceShow"
      :title="$t('withdraw.invoice')"
      :showConfirmButton="false"
    >
    <div class="invoice-dialog-main padding20">
      <div class="main-text-2 font14">
        {{ $t('withdraw.invoiceDialogContent') }}
      </div>
      <div class="dialog-footer-btn-box ub ub-btw text-c margin-top24">
        <div class="btn-box background-color-3 primary-btn-color font16"  @click="invoiceShowFn">
          {{ $t('withdraw.iKonw') }}
        </div>
      </div>
    </div>
    </van-dialog>
  </div>
</template>
<script>
import { fundWithdraw } from '@/api/assetsCenter.js'
import SelectAddress from '@/components/withdraw/selectAddress/index.vue'
import PullRefresh from '@/components/pullRefresh/index.vue'
import ChooseNetwork from '@/components/withdraw/chooseNetwork/index.vue'
import Vue from 'vue'
import { NavBar } from 'vant'
Vue.use(NavBar)
export default {
  components: { PullRefresh, ChooseNetwork, SelectAddress },
  computed: {
    disabled () {
      let status = true
      if (!this.withdrawAddress || !this.amount || !this.network.network_type || this.amount > this.totalAssets || this.amount === '0') {
        status = true
      } else {
        status = false
      }
      return status
    }
  },
  data () {
    return {
      coinInfo: {},
      network: {},
      withdrawAddress: '',
      amount: 0,
      fee: 1,
      invoiceShow: false,
      totalAssets: 0
    }
  },
  mounted () {
    this.totalAssets = this.$store.state.totalAssets.available_amount
    this.coinInfo = this.$route.query
    console.log(this.$route.query)
  },
  methods: {
    refreshFn () {
      this.$refs.ChooseNetwork.fundNetworkListFn()
      console.log('ChooseNetwork---')
    },
    setMaxAmount () {
      this.amount = this.totalAssets
    },
    goHistory () {
      this.$router.push({
        path: '/history/walltes/withdraw'
      })
    },
    invoiceShowFn () {
      this.invoiceShow = !this.invoiceShow
    },
    getNetwork (value) {
      this.network = value
      this.totalAssets = this.totalAssets > 1 ? this.totalAssets - this.network.fee : 0
      console.log(value)
    },
    getAddress (value) {
      console.log(value)
      this.network = {
        chooseNetwork: value.netWork
      }
      this.withdrawAddress = value.address
    },
    fundWithdrawFn () {
      if (!this.withdrawAddress || !this.amount || !this.network.network_type || this.amount === '0') {
        return false
      }
      if (this.amount > this.totalAssets) {
        return false
      }
      const address = this.withdrawAddress
      const amount = this.amount
      const fee = this.network.fee
      const networkType = this.network.network_type
      fundWithdraw({
        address: address,
        amount: amount,
        fee: fee,
        network_type: networkType
      }).then(res => {
        this.$toast.clear()
        console.log(res, 'resfundWithdraw----')
      })
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.withdraw-info-main{
  height: calc(100vh - 13.33333vw);
  padding-bottom: 200px;
  overflow: scroll;
  box-sizing: border-box;
  background-color: #fff;
  .withdraw-content-box{
    .van-cell{
      background-color: rgba(0,0,0,0);
      padding: 0;
    }
    .footer-btn-box{
      background-color: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 40px;
      box-sizing: border-box;
      .btn-box{
        box-sizing: border-box;
        padding: 24px 0;
        width: 100%;
        border-radius: 8px;
      }
      .disabled-bg-color{
        background: #BAB7BF;
      }
    }
  }
}
.invoice-dialog-main{
  .dialog-footer-btn-box{
    width: 100%;
    box-sizing: border-box;
    .btn-box{
      box-sizing: border-box;
      padding: 24px 0;
      width: 100%;
      border-radius: 8px;
    }
  }
}
.content-box{
}
</style>
