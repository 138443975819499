<template>
  <div class="network-withdraw-wrap">
    <NetworkWithdrawCpt></NetworkWithdrawCpt>
  </div>
</template>
<script>
import NetworkWithdrawCpt from '@/components/withdraw/networkWithdraw/index.vue'
export default {
  name: 'NetworkWithdraw',
  components: { NetworkWithdrawCpt },
  computed: {
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>
