<template>
  <div class="selectAddress-wrap">
    <div class="selectAddress ub ub-cen" @click="popupShowFn">
      <img src="@/assets/images/walltes/book.svg"/>
    </div>
    <van-popup v-model="popupShow" position="bottom">
      <div class="selectAddress-list background-color-4">
        <van-nav-bar left-text=" " :border="false" >
          <p slot="left" class="font16 font-weight700">{{ $t('withdraw.selectAddress') }}</p>
          <img
            @click="onCancel"
            slot="right"
            src="@/assets/images/system/cross.svg"
            alt="close"
            title="close"
          />
        </van-nav-bar>
        <div class="withdraw-selectAddress-main">
          <!-- <div class="padding-left20 padding-right20 padding-top20 main-text-3">
            {{ $t('selectAddress.tip') }}
          </div> -->
          <ul class="list-box padding20">
            <li class="padding12 background-color-2 margin-top8" v-for="(item,index) in filterselectAddress" :key="index" @click="onConfirm(item)">
              <p class="netWork-box text-c font10 margin-bottom8">
                {{ item.netWork }}
              </p>
              <p class="font14 main-text-1 break-word">
                {{ item.address }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Picker, Icon } from 'vant'
import Vue from 'vue'

Vue.use(Popup)
Vue.use(Picker)
Vue.use(Icon)

export default {
  name: 'selectAddress',
  props: {
  },
  data () {
    return {
      value: '',
      columns: [
        { address: '0x1kjabsczbxhcvzgxchg123123123123asasdas8A', netWork: 'ETHEREUM' },
        { address: '0x2SDSDKJKJKJKJKJKJKJKJDCNCNCNCNCNCNCNCNCB', netWork: 'OPTIMISM' },
        { address: '0x3KJISJSNCJKJHJGHGSVHGASASASASASASASASASD', netWork: 'ARBITRUM' },
        { address: '0xadfdfdhfvjhsgdfjhgasjhdgahsvdhasdjhasgdE', netWork: 'POLYGON' }
      ],
      selectAddressObj: { address: '' },
      popupShow: false
    }
  },
  computed: {
    filterselectAddress () {
      let list = []
      if (this.value) {
        list = this.columns.filter((item) => {
          return item.selectAddress.indexOf(this.value) !== -1
        })
      } else {
        list = this.columns
      }
      return list
    }
  },
  methods: {
    clearSearch () {
      this.value = ''
    },
    popupShowFn () {
      this.popupShow = true
    },
    onConfirm (value) {
      this.popupShow = false
      this.selectAddressObj = value
      this.$emit('callback', value)
    },
    onCancel () {
      this.popupShow = false
    }
  }
}
</script>
<style lang="less">
.selectAddress-wrap{
  .selectAddress{
    .icon-box{
      width: 80px;
      height: 40px;
    }
  }
}
.van-popup{
  background-color: rgba(0, 0, 0, 0);
}
.selectAddress-list{
  width: 100%;
  height: 90vh;
  overflow: hidden;
  border-top-left-radius: 5vw;
  border-top-right-radius: 5vw;
  .withdraw-selectAddress-main{
    height: 90vh;
    overflow: scroll;
    .list-box{
      box-sizing: border-box;
      overflow: scroll;
      li{
        border-radius: 8px;
        .netWork-box{
          display: inline-block;
          padding: 4px 8px;
          gap: 10px;
          border-radius: 4px;
          color:#5B21CB;
          background: rgba(91, 33, 203, 0.12);
        }
      }
    }
  }
}
</style>
