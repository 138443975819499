<template>
  <div class="chooseNetwork-wrap padding20">
    <div class="chooseNetwork" @click="popupShowFn">
      <div class="ub ub-h-c">
        <p class=" main-text-2 margin-right8">{{ $t('chooseNetwork.network') }}</p>
        <img src="@/assets/images/walltes/tip.svg" alt="">
      </div>
      <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
        <div class="main-text-3 font14" v-show="!chooseNetworkObj.network_type">{{ $t('chooseNetwork.slelectNetworkTip') }}</div>
        <div class="main-text-1 font14 font-weight400" v-show="chooseNetworkObj.network_type">
          {{ chooseNetworkObj.network_type }}
        </div>
        <div>
          <img src="@/assets/images/walltes/arrow-right.svg" alt="">
        </div>
      </div>
    </div>
    <van-popup v-model="popupShow" position="bottom" :get-container="'.chooseNetwork-wrap'">
      <div class="chooseNetwork-list background-color-4">
        <van-nav-bar left-text=" " :border="false" >
          <p slot="left" class="font16 font-weight700">{{ $t('chooseNetwork.network') }}</p>
          <img
            @click="onCancel"
            slot="right"
            src="@/assets/images/system/cross.svg"
            alt="close"
            title="close"
          />
        </van-nav-bar>
        <div class="withdraw-chooseNetwork-main">
          <!-- <div class="padding-left20 padding-right20 padding-top20 main-text-3">
            {{ $t('chooseNetwork.tip') }}
          </div> -->
          <ul class="list-box padding20">
            <li class="padding16 background-color-2 margin-top8" v-for="(item,index) in filterchooseNetwork" :key="index" @click="onConfirm(item)">
              <p class="font14 main-text-1">
                {{ item.network_type }}
              </p>
              <p class="font12 main-text-2">
                Fee {{ item.fee }} USDT (≈ ${{ item.fee }})
              </p>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { fundNetworkList } from '@/api/assetsCenter.js'
import { Popup, Picker, Icon } from 'vant'
import Vue from 'vue'

Vue.use(Popup)
Vue.use(Picker)
Vue.use(Icon)

export default {
  name: 'chooseNetwork',
  props: {
    network: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    network: {
      handler: function () {
        this.chooseNetworkObj = this.network
      },
      deep: true
    }
  },
  data () {
    return {
      value: '',
      columns: [
      ],
      chooseNetworkObj: {},
      popupShow: false
    }
  },
  computed: {
    filterchooseNetwork () {
      let list = []
      if (this.value) {
        list = this.columns.filter((item) => {
          return item.network_type.indexOf(this.value) !== -1
        })
      } else {
        list = this.columns
      }
      return list
    }
  },
  mounted () {
    this.fundNetworkListFn()
  },
  methods: {
    fundNetworkListFn () {
      fundNetworkList({}).then(res => {
        console.log(res, 'res=======')
        this.columns = res.data
        this.chooseNetworkObj = this.columns[0]
        this.$emit('callback', this.chooseNetworkObj)
        this.$toast.clear()
      })
    },
    clearSearch () {
      this.value = ''
    },
    popupShowFn () {
      this.popupShow = true
    },
    onConfirm (value) {
      this.popupShow = false
      this.chooseNetworkObj = value
      this.$emit('callback', value)
    },
    onCancel () {
      this.popupShow = false
    }
  }
}
</script>
<style lang="less">
.chooseNetwork-wrap{
  .chooseNetwork{
    .icon-box{
      width: 80px;
      height: 40px;
    }
  }

.van-popup{
  background-color: rgba(0, 0, 0, 0);
}
.chooseNetwork-list{
  width: 100%;
  height: 90vh;
  overflow: hidden;
  border-top-left-radius: 5vw;
  border-top-right-radius: 5vw;
  .withdraw-chooseNetwork-main{
    height: 90vh;
    overflow: scroll;
    .list-box{
      box-sizing: border-box;
      overflow: scroll;
      li{
        border-radius: 8px;
      }
    }
  }
}
}
</style>
